




































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}
@Component({
  components: {}
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aNoticiasCategoria: Array<any> = [];
  private nombreCategoria: any = "";
  private currentPage = 1;
  private total = 0;
  private win = window;
  private aBanners: Array<dataBanner> = [];
    private slideIndex: number = 1;
  private slide = 1;


  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 8)) {
      this.getInfoNoticiaCategoria();
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticiaCategoria();
          this.getBanners();

    }
  }

    private getBanners() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("slider/noticias")
      .then((res) => {
        // console.log(res.data);
        // console.log("noti", res.data);
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile = this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }


  private getInfoNoticiaCategoria() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "noticias/categoria?pagina=" +
          this.currentPage +
          "&nombre_web=" +
          this.nombreWeb
      )
      .then((res: any) => {
        // console.log(res.data);
        let base = process.env.VUE_APP_BASE_URL;
        this.aNoticiasCategoria = res.data.noticias;
        this.nombreCategoria = res.data.nombre;
         for (let i = 0; i < this.aNoticiasCategoria.length; i++) {
          if (
            this.aNoticiasCategoria[i].fotos.image_fulltext &&
            this.aNoticiasCategoria[i].fotos.image_fulltext != ""
          ) {
            this.aNoticiasCategoria[i].fotos.image_fulltext =
              base + this.aNoticiasCategoria[i].fotos.image_fulltext;
          } else {
            this.aNoticiasCategoria[
              i
            ].fotos.image_intro = require("@/assets/logos/logo300.webp");
            this.aNoticiasCategoria[
              i
            ].fotos.image_fulltext = require("@/assets/logos/logo300.webp");
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.aNoticiasCategoria = [];
        this.nombreCategoria = this.nombreWeb.replace("-", " ");
        const arr = this.nombreCategoria.split(" ");
        //loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        //Join all the elements of the array back into a string
        //using a blankspace as a separator
        this.nombreCategoria = arr.join(" ");

        this.$q.loading.hide();
        console.log(err);
      });
  }

 private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);
    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return (
        "Publicado el " + diaInicio + " de " + mesInicio + ", " + anhoInicio
      );
    }
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }

    private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }
  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
    }
  }
  private updated() {
    this.showSlides(this.slideIndex);
  }

  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 9000);
  }
}
